<template>
  <div>
    <GraphsMenu menu="kams-boss"></GraphsMenu><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          Marcas - Kam
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>      
              <CCol md="3" style="text-align: right;">
                <CButton color="success" size="sm" @click="downloadExcel">Descargar Excel</CButton>
              </CCol>
              <CCol md="4" style="text-align: right;">
                <CSelect class="m-0"
                  :value.sync="monthSelect" 
                  :options="monthsOptions"
                  @change="changeFilters"
                />  
              </CCol>
              <CCol md="5" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="kamSelect" 
                  :options="kamsOptions"
                  @change="changeFilters"
                />   
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="6">
            <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="customers"
              :fields="fields"
              :items-per-page="15"
              :dark="false"
              pagination
              style="width: 100%; font-size: 10px;"
              :table-filter="{label: 'Buscar: ', placeholder: '...'}"
            >
              <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
              <template #total_universe="{item}">
                <td class="text-center w50">
                  {{item.total_universe}}        
                </td>
              </template>
              <template #total_exists="{item}">
                <td class="text-center w50">
                  {{item.total_exists}}        
                </td>
              </template>
              <template #completed="{item}">
                <td class="text-center w50">
                  {{item.completed}}        
                </td>
              </template>
              <template #acepted="{item}">
                <td class="text-center w50">
                  {{item.acepted}}        
                </td>
              </template>
              <template #performance="{item}">
                <td class="text-center w50">
                  {{item.performance}}%
                </td>
              </template>
              <template #pending="{item}">
                <td class="text-center w50">
                  <template v-if="item.pending > 0">
                    <a  href="#" @click="openDetailBranchModal(item.customer_id)">{{item.pending}}</a>  
                  </template>
                  <template v-else>
                    {{item.pending}}
                  </template>
                </td>
              </template>
            </CDataTable>
          </CCol>
          <CCol md="6">
              <CRow>           
                <CCol md="12">
                  <CCol md="5" style="text-align: right; padding-right: 0px;">
                    <CSelect class="m-0" style="float: right; width: 100%;"
                      :value.sync="customerSelect" 
                      :options="customersOptions"
                      @change="changeFiltersGraphs"
                    />   
                  </CCol>                                                            
                </CCol>                     
              </CRow>
              <br>
              <CRow>           
                <CCol md="4">
                  <CWidgetSimple style="height: 125px;" header="Total Visitas Programadas" :text="String(metrics.total_exists)"></CWidgetSimple>
                </CCol>     
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.completed)"
                    text="Total Visitas Realizadas"
                    color="success"
                    :value="Number(metrics.completed_percent)"
                    >
                    </CWidgetProgress>
                </CCol>  
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.acepted)"
                    text="Total Visitas Aceptadas"
                    color="success"
                    :value="Number(metrics.acepted_percent)"
                    >
                    </CWidgetProgress>
                </CCol>   
              </CRow>
              <CRow>     
                <CCol md="4">
                  <GaugePercentSemaforoGraph ref="graphPercent" title="Total Visitas Programadas" ></GaugePercentSemaforoGraph>
                </CCol>                                   
                <CCol md="4">
                  <DonoutSemaforoGraph ref="graphDonout" title="Total Visitas Realizadas" isMini="1"></DonoutSemaforoGraph>
                </CCol>  
                <CCol md="4">
                  <GaugeSemaforoGraph ref="graphGauge" title="Total Visitas Aceptadas" isMini="1"></GaugeSemaforoGraph>
                </CCol>      
              </CRow>
          </CCol>          
        </CRow>
      </CCardBody>
    </CCard>
    <DetailBranchModal ref="detailBranchModal"></DetailBranchModal>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import DonoutSemaforoGraph from './graphs/DonoutSemaforoKams.vue';
import GaugeSemaforoGraph from './graphs/GaugeSemaforo.vue';
import GaugePercentSemaforoGraph from './graphs/GaugePercentSemaforo.vue';
import GraphsMenu from './GraphsMenu.vue';
import DetailBranchModal from './modals/BranchsKamsModal.vue';

export default {
  name: 'GraphsKamsBoss',
  components: {
    DonoutSemaforoGraph,
    GaugeSemaforoGraph,
    GaugePercentSemaforoGraph,
    GraphsMenu,
    DetailBranchModal
  },
  data () {
    return {
      code: 'general',
      customers: [],
      fields: [
          {key: 'customer_name', label: 'Marca'},
          {key: 'total_universe', label: 'Total Universo' ,_style:" width: 50px;"},
          {key: 'total_exists', label: 'Total Programadas' ,_style:" width: 50px;"},
          {key: 'completed', label: 'Realizadas',_style:" width: 50px;"},
          {key: 'acepted', label: 'Aceptadas', _style:" width: 50px;"},
          {key: 'performance', label: 'Cumplimiento', _style:" width: 50px;"},
          {key: 'pending', label: 'Pendientes', _style:" width: 50px;"},
        ],      
      kamSelect: "",
      graphsSelect: "",
      graphsOptions:[],
      kamsOptions: [],
      customersOptions: [],
      customerSelect: "",
      monthSelect: "202409",
      monthsOptions: [],
      graphs_gauge: 0,
      semaforo: 0,
      graphs_percent:0,
      graphs_donout:[{code: '',service : "",total : "0",}],
      metrics:{
        total_exists : "0",
        acepted : "0",
        acepted_percent: "0",
        completed : "0",
        completed_percent: "0",
      }
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_kam = localStorage.getItem("is_kam");

      if(is_kam == "false"){
        window.location.href = "/#/home"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/welcome"; this.loaded(); return;
      }
    }

    let response = await ws.getKamsBoss(); 
    if(response.type == "success"){
      this.kamsOptions = await this.$parseSelectOptions(response.data, "id", "name",[{"label": "Todos","value":""}]);
      if(response.data.length > 0){
        this.kamSelect = this.kamsOptions[1].value;
      }      
    }

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          customer_id: "",
        };
        let responseMetrics = await ws.getMetricsKamsBoss(filters);
        if(responseMetrics.type == "success"){
          this.metrics = responseMetrics.data;
          this.graphs_percent = responseMetrics.data.graph_percent;      
          this.graphs_donout = responseMetrics.data.graph_donout;     
          this.graphs_gauge = responseMetrics.data.graph_guage;
        }
        let response_table = await ws.getTableKamsBoss(filters);
        if(response_table.type == "success"){
          this.customers = response_table.data.customers;          
          this.customersOptions = await this.$parseSelectOptions(this.customers, "customer_id", "customer_name",[{"label": "Todos","value":""}]);
          this.customerSelect = "";
        }
        this.chargeGraphs(); 
      this.loaded();
    },
    async changeFiltersGraphs () {
      this.loading();
        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          customer_id: this.customerSelect,
        };
        let responseMetrics = await ws.getMetricsKamsBoss(filters);
        if(responseMetrics.type == "success"){
          this.metrics = responseMetrics.data;          
          this.graphs_percent = responseMetrics.data.graph_percent;      
          this.graphs_donout = responseMetrics.data.graph_donout;     
          this.graphs_gauge = responseMetrics.data.graph_guage;
        }        
        this.chargeGraphs(); 
      this.loaded();
    },
    async chargeGraphs(){
        await this.$refs.graphPercent.loadGraph(this.graphs_percent);
        await this.$refs.graphDonout.loadGraph(this.graphs_donout);
        await this.$refs.graphGauge.loadGraph(this.graphs_gauge);
    },
    async openDetailBranchModal(customer_id){
      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          customer_id: customer_id,
        };
        await this.$refs.detailBranchModal.showBranchsKamsModal(filters);
    },
    async downloadExcel(){
      this.loading();

      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
        };
      await ws.downloadExcelKamsBoss(filters);
      
      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
